import utils from "@/stuff/Utils";
import { mapData } from "@/stuff/DataMapping";

export interface ITrade {
    id: number;
    description: string;
    created: Date;
    createdByUserID: number;
    lastUpdated: Date;
    lastUpdatedByUserID: number;
    deleted: Date;
    deletedByUserID: number;
    isConsultant: boolean;
    isContractor: boolean;
}

export class Trade implements ITrade {

    constructor(data?: ITrade) {
        if (typeof data === "undefined" || data === null) return;
        this.update(data);
    }

    update(data: ITrade) {
        mapData(data, { root: () => this });
    }

    id: number = 0;
    description: string = "";
    created: Date = new Date(utils.emptyDateValue);
    createdByUserID: number = 0;
    lastUpdated: Date = new Date(utils.emptyDateValue);
    lastUpdatedByUserID: number = 0;
    deleted: Date = new Date(utils.emptyDateValue);
    deletedByUserID: number = 0;
    isConsultant: boolean = false;
    isContractor: boolean = false;

    get isNew() {
        return utils.isEmptyId(this.id);
    }

    get isDeleted() {
        return utils.hasDateValue(this.deleted);
    }
}
