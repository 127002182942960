
    import Vue from "vue";
    import Component from "vue-class-component";
    import { StateChanger } from "vue-infinite-loading";
    import ApiButton from "@/components/ApiButton.vue";
    import { required } from "vuelidate/lib/validators";
    import { Validations } from "vuelidate-property-decorators";
    import { Watch } from "vue-property-decorator";
    import * as toastr from "toastr";
    import apiClient from "@/stuff/ApiClient";
    import { TradeSearchParameters } from "@/model/TradeSearchParameters";
    import { LookupItem } from "@/model/LookupItem";
    import { Trade, ITrade } from "@/model/Trade";
    import utils from "@/stuff/Utils";
    import { TriState } from "@/model/Enums";

    @Component({
        components: { ApiButton }
    })
    export default class Trades extends Vue {

        //
        // -- properties
        //

        private searchParameters = new TradeSearchParameters();
        private totalCount = -1;
        private tradeList: Array<ITrade> = [];
        private readonly trade = new Trade();

        // computed
        private get dialogueTitle(): string {
            if (!this.trade) return "- - -";
            if (utils.isEmptyId(this.trade.id)) return "New Trade";
            return "Edit " + this.trade.description;
        }

        private get countText(): string {
            return this.totalCount === -1 ? "..." : this.totalCount.toString();
        }

        private get isDeletedSearchOptions(): Array<LookupItem> { return [
            new LookupItem({ id: TriState.UseDefault, description: "All", isArchived: false }),
            new LookupItem({ id: TriState.True, description: "Deleted", isArchived: false }),
            new LookupItem({ id: TriState.False, description: "Not deleted", isArchived: false })
        ];}

        //
        // -- Watchers
        //

        @Watch("searchParameters.isDeleted")
        onIsDeletedChanged(value: number) {
            console.log("Is deleted changed = ", value);
            this.refreshSearch();
        }

        //
        // -- methods
        //

        async edit(tradeData: ITrade) {
            this.trade.update(tradeData);
            // reset any validation
            this.$v.$reset();
            // show the dialogue
            this.$bvModal.show("tradeDialogue");
			// refresh from server
            const serverTradeData = await apiClient.get(`api/trade/Load?id=${tradeData.id}`);
            this.trade.update(serverTradeData);
        }

        editNew() {
            utils.resetObject(this.trade);
            this.$v.$reset();
            this.$bvModal.show("tradeDialogue");
        }

        cancel() {
            this.$bvModal.hide("tradeDialogue");
            utils.resetObject(this.trade);
        }

        async save(event: Event) {

            // 'touch' all the fields to activate the validation messages
            this.$v.$touch();
            if (this.$v.trade.$invalid) {
                toastr.info("Please fix the highlighted errors", "Validation errors");
                return;
            }
            const response: { newID: number } =  await apiClient.post("/api/trade/save", this.trade, event);
            if (this.trade.isNew) {
                this.trade.id = response.newID;
            }
            toastr.success("Saved");           
            this.$bvModal.hide("tradeDialogue");
            // redo search - or should we just update in place?
            await this.refreshSearch();
        }

        // 'delete' is a reserved word
        async deleteItem(event: Event) {
            if (this.trade.isNew) return;
            const shouldDelete: boolean = await this.$bvModal.msgBoxConfirm("Do you want to delete '" + this.trade.description + "'?", {
                title: "Delete",
                okVariant: "danger",
                okTitle: "Yes, delete!",
                cancelTitle: "No, leave it",
                hideHeaderClose: true,
                centered: true,
                headerClass: "border-bottom-0",
                footerClass: "border-top-0",
                size: "sm"
            });
            if (!shouldDelete) return;
            await apiClient.post("/api/trade/delete", this.trade, event);
            toastr.warning("Deleted");
            this.$bvModal.hide("tradeDialogue");
            await this.refreshSearch();
        }

        refreshSearch(event?: Event) {
            this.tradeList = [];
            this.searchParameters.pageNumber = 1;
            this.totalCount = -1;
            this.search(event);
        }

        async infiniteLoadingHandler(stateChanger: StateChanger) {
            if (this.tradeList.length >= this.totalCount && this.totalCount > -1) {
                stateChanger.complete();
                return;
            }
            this.searchParameters.pageNumber += 1;
            await this.search();
            if (this.tradeList.length >= this.totalCount) {
                stateChanger.complete();
            }
            else {
                stateChanger.loaded();
            }           
        }

        private async search(event?: Event) {
            const response = await apiClient.post("/Api/Trade/Search", this.searchParameters, event);
            if (this.searchParameters.pageNumber === 1) {
                this.totalCount = response.count;
            }
            this.tradeList.push(...response.list.map((s: ITrade) => new Trade(s)));
        }

        @Validations()
        validations() {
            const validations = {
                trade: {} as any // eslint-disable-line @typescript-eslint/no-explicit-any
            };
            validations.trade.description = { required };
            return validations;
        }
    }
